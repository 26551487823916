import company from '@/store/modules/company'
import accountingFirm from '@/store/modules/accountingFirm'

function enrichStatusWithWorkflowDetails(status, workflowMap) {
  const workflow = workflowMap.get(status.workflow_id);
  return {
    ...status,
    workflow: { id: workflow.id, name: workflow.name }
  };
}

export default {
  namespaced: true,
  state: {
    workflows: [],
    versionsWorkflowStatuses: {},
  },
  mutations: {
    setVersionsWorkflowsStatuses: function (state, { statuses, versionId }) {
      const workflowMap = new Map(state.workflows.map(wf => [wf.id, wf]));

      // Enrich statuses with workflow details
      const enrichedStatuses = statuses
        .filter(status => workflowMap.has(status.workflow_id))
        .map(status => enrichStatusWithWorkflowDetails(status, workflowMap))
        .sort((a, b) => b.id - a.id);

      // Set the enriched statuses in the versionsWorkflowStatuses object, keyed by versionId
      state.versionsWorkflowStatuses = {
        ...state.versionsWorkflowStatuses,
        [versionId]: enrichedStatuses,
      };
    },

    addVersionWorkflowStatus: function (state, { status, versionId }) {
      const existingStatuses = state.versionsWorkflowStatuses[versionId] || [];
      const workflowMap = new Map(state.workflows.map(wf => [wf.id, wf]));

      // Add the new status only if it doesn't already exist
      const updatedStatuses = existingStatuses.some(s => s.id === status.id)
        ? existingStatuses
        : [...existingStatuses, status];

      const enrichedStatuses = updatedStatuses
        .filter(status => workflowMap.has(status.workflow_id))
        .map(status => enrichStatusWithWorkflowDetails(status, workflowMap))
        .sort((a, b) => b.id - a.id);

      // Set the updated statuses in the versionsWorkflowStatuses object
      state.versionsWorkflowStatuses = {
        ...state.versionsWorkflowStatuses,
        [versionId]: enrichedStatuses,
      };
    },

    setWorkflows: function (state, workflows) {
      state.workflows = workflows;
    },

    replaceWorkflow: function (state, workflow) {
      state.workflows = [...state.workflows.filter(wf => wf.id !== workflow.id), workflow];
    },

    removeWorkflow: function (state, { workflowId, versionId }) {
      const workflow = state.workflows.find(wf => wf.id === workflowId);
      if (workflow) {
        const existingStatuses = state.versionsWorkflowStatuses[versionId] || [];
        const updatedStatuses = existingStatuses.filter(status => status.workflow_id !== workflowId);
        state.versionsWorkflowStatuses = {
          ...state.versionsWorkflowStatuses,
          [versionId]: updatedStatuses,
        };
      }
    },

    reset: function (state) {
      state.workflows = []
      state.versionsWorkflowStatuses = {}
    },

    resetVersionsWorkflowStatuses: function (state) {
      state.versionsWorkflowStatuses = {}
    },
  },
  actions: {
    reset: function ({ commit }) {
      return commit('reset')
    }
  },
  getters: {
    versionsWorkflowStatuses: (state) => (versionId) => {
      return state.versionsWorkflowStatuses[versionId] || [];
    },
    userHasWriteAccess: function () {
      if (company.state.userRoles?.isGuest) {
        return false
      }

      return company.state.userRoles?.isAdmin && !accountingFirm.state.selected?.isAccountant || accountingFirm.state.selected?.isAccountantAdmin
    }
  },
};
