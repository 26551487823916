// ============================================================================
// VueX - Documents
// ----------------
// Documents VueX store module
// ============================================================================
import automations from '@/store/modules/documents/automations'
import edition from '@/store/modules/documents/edition'
import themes from '@/store/modules/documents/themes'
import preview from "@/store/modules/documents/preview"
import selection from "@/store/modules/documents/selection"

import { Structure } from '@/classes/Documents/Structure.class.js'

const modules = {
	automations,
	edition,
	themes,
	preview,
	selection
}

export default {
	namespaced: true,
	modules,
	state: {
		displayMode: localStorage.getItem('ecmDisplayMode'),
		treeStructure: new Structure(),
		filter: {
			unvalidated: true,
			validated: true,
			search: {
				filename: null,
				results: null
			}
		},
		isFiltering: false,
		sort: null
	},
	mutations: {
		setDisplayMode: function (state, value = null) {
			const defaultValue = window.vueInstance?.$vuetify?.breakpoint.smAndDown ? 'list' : 'mosaic'
			state.displayMode = value ? value : defaultValue
			localStorage.setItem('ecmDisplayMode', state.displayMode)
		},
		addOrUpdateNodeInStructure: function (state, node) {
			const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
			if (state.isFiltering) {
				structure.update(node)
			} else {
				structure.addOrUpdateNode(node)
				structure.tryToAttachGhostNodes()
			}
		},
		updateNodeInStructure: function (state, node) {
			const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
			structure.update(node)
		},
		removeNodeFromStructure: function (state, node) {
			const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
			structure.remove(node)
		},
		markFolderAsOpened: function (state, folder) {
			if (!state.isFiltering) {
				const structure = state.treeStructure
				const retrievedFolder = structure.findNode(folder.id)
				if (retrievedFolder) {
					retrievedFolder.markAsOpened()
				}
			}
		},
		markFolderIdAsOpened: function (state, folderId) {
			if (!state.isFiltering) {
				const structure = state.treeStructure
				const retrievedFolder = structure.findNode(folderId)
				if (retrievedFolder) {
					retrievedFolder.markAsOpened()
				}
			}
		},
		markFolderAsClosed: function (state, folder) {
			if (!state.isFiltering) {
				const structure = state.treeStructure
				const retrievedFolder = structure.findNode(folder.id)
				if (retrievedFolder) {
					retrievedFolder.markAsClosed()
				}
			}
		},
		markFolderIdAsClosed: function (state, folderId) {
			if (!state.isFiltering) {
				const structure = state.treeStructure
				const retrievedFolder = structure.findNode(folderId)
				if (retrievedFolder) {
					retrievedFolder.markAsClosed()
				}
			}
		},
		removeDocumentVersion: function (state, version) {
			let document = null
			if (version?.document_id) {
				const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
				document = structure.findNode(version.document_id)
			}
			if (document) {
				document.removeVersion(version)
			}
		},
		addOrUpdateDocumentVersion: function (state, version) {
			let document = null
			if (version?.document_id) {
				const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
				document = structure?.findNode(version.document_id)
			}
			if (document) {
				document.addOrUpdateVersion(version)
			}
		},
		updateDocumentVersion: function (state, version) {
			let document = null
			if (version?.document_id) {
				const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
				document = structure.findNode(version.document_id)
			}
			if (document) {
				document.updateVersion(version)
			}
		},
		setDocumentVersionAuthor: function (state, versionAuthor) {
			let version = null
			let document = null
			if (versionAuthor?.documentId) {
				const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
				document = structure.findNode(versionAuthor.documentId)
			}
			if (document && document.versions) {
				version = document.versions.find(aVersion => aVersion.id === versionAuthor.versionId)
			}
			if (version) {
				version.author = versionAuthor.author
			}
		},
		setDocumentVersionValidator: function (state, versionValidator) {
			let version = null
			let document = null
			if (versionValidator?.documentId) {
				const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
				document = structure.findNode(versionValidator.documentId)
			}
			if (document && document.versions) {
				version = document.versions.find(aVersion => aVersion.id === versionValidator.versionId)
			}
			if (version) {
				version.validator = versionValidator.validator
			}
		},
		addOrUpdateDocumentVersionSignature: function (state, signature) {
			let version = null
			let document = null
			if (signature?.document_id) {
				const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
				document = structure.findNode(signature.document_id)
			}
			if (document && document.versions && signature.version_id) {
				version = document.versions.find(aVersion => aVersion.id === signature.version_id)
			}
			if (version) {
				version.signatures = signature
			}
		},
		setIsFiltering: function (state, isFiltering) {
			state.isFiltering = isFiltering
		},
		setNodeCounter: function (state, nodeCounter) {
			const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
			const node = structure?.findNode(nodeCounter.nodeId)
			if (node) {
				node.counter = nodeCounter.counter
			}
		},
		setNodeIsSurveyed: function (state, nodeSurveyed) {
			const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
			const node = structure?.findNode(nodeSurveyed.nodeId)
			if (node) {
				node.is_surveyed = nodeSurveyed.isSurveyed
			}
		},
		setVersionSignatures: function (state, signatures) {
			let version = null
			let document = null
			if (signatures?.documentId) {
				const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
				document = structure.findNode(signatures.documentId)
			}
			if (document) {
				version = document.versions.find(aVersion => aVersion.id === signatures.versionId)
			}
			if (version) {
				version.has_signatures = signatures.has_signatures
			}
		},
		setVersionSignatureStatus: function (state, signatureStatus) {
			let version = null
			let document = null
			if (signatureStatus?.documentId) {
				const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
				document = structure.findNode(signatureStatus.documentId)
			}
			if (document) {
				version = document.getVersion({ id: signatureStatus.document_version_id })
			}
			if (version) {
				version.signatures = signatureStatus
			}
		},
		setValidatedFilter: function (state, validated) {
			state.filter.validated = validated
		},
		setUnvalidatedFilter: function (state, unvalidated) {
			state.filter.unvalidated = unvalidated
		},
		setSearchFilename: function (state, searchedFilename) {
			state.filter.search.filename = searchedFilename
		},
		setSearchResults: function (state, searchResults) {
			state.filter.search.results = searchResults
		},
		resetSearchFilter: function (state) {
			state.isFiltering = false
			state.filter.search.filename = null
			state.filter.search.results = null
		},
		resetFilter: function (state) {
			state.filter.unvalidated = true
			state.filter.validated = true
		},
		setSort: function (state, folderSort) {
			const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
			const node = structure?.findNode(folderSort.folderId)
			if (node) {
				node.sort = folderSort.sort
			}
			state.sort = folderSort
		},
		reset: function (state) {
			state.treeStructure = new Structure()
			state.sort = null
		}
	},
	actions: {
		setDisplayMode: function ({ commit }, value = null) {
			return commit('setDisplayMode', value)
		},
		addOrUpdateNodeInStructure: function ({ commit }, node) {
			return commit('addOrUpdateNodeInStructure', node)
		},
		updateNodeInStructure: function ({ commit }, node) {
			return commit('updateNodeInStructure', node)
		},
		removeNodeFromStructure: function ({ commit }, node) {
			return commit('removeNodeFromStructure', node)
		},
		markFolderAsOpened: function ({ commit }, folder) {
			return commit('markFolderAsOpened', folder)
		},
		markFolderIdAsOpened: function ({ commit }, folderId) {
			return commit('markFolderIdAsOpened', folderId)
		},
		markFolderAsClosed: function ({ commit }, folder) {
			return commit('markFolderAsClosed', folder)
		},
		markFolderIdAsClosed: function ({ commit }, folderId) {
			return commit('markFolderIdAsClosed', folderId)
		},
		addOrUpdateDocumentVersion: function ({ commit }, version) {
			return commit('addOrUpdateDocumentVersion', version)
		},
		updateDocumentVersion: function ({ commit }, version) {
			return commit('updateDocumentVersion', version)
		},
		setDocumentVersionAuthor: function ({ commit }, versionAuthor) {
			return commit('setDocumentVersionAuthor', versionAuthor)
		},
		setDocumentVersionValidator: function ({ commit }, versionValidator) {
			return commit('setDocumentVersionValidator', versionValidator)
		},
		addOrUpdateDocumentVersionSignature: function ({ commit }, signature) {
			return commit('addOrUpdateDocumentVersionSignature', signature)
		},
		setIsFiltering: function ({ commit }, isFiltering) {
			return commit('setIsFiltering', isFiltering)
		},
		setNodeCounter: function ({ commit }, nodeCounter) {
			return commit('setNodeCounter', nodeCounter)
		},
		setNodeIsSurveyed: function ({ commit }, nodeSurveyed) {
			return commit('setNodeIsSurveyed', nodeSurveyed)
		},
		setValidatedFilter: function ({ commit }, validated) {
			return commit('setValidatedFilter', validated)
		},
		setUnvalidatedFilter: function ({ commit }, unvalidated) {
			return commit('setUnvalidatedFilter', unvalidated)
		},
		setSearchFilename: function ({ commit }, search) {
			return commit('setSearchFilename', search)
		},
		setSearchResults: function ({ commit }, searchResults) {
			return commit('setSearchResults', searchResults)
		},
		setSort: function ({ commit }, sort) {
			return commit('setSort', sort)
		},
		setVersionSignatures: function ({ commit }, signatures) {
			return commit('setVersionSignatures', signatures)
		},
		setVersionSignatureStatus: function ({ commit }, signatureStatus) {
			return commit('setVersionSignatureStatus', signatureStatus)
		},
		resetSearchFilter: function ({ commit }) {
			return commit('resetSearchFilter')
		},
		resetFilter: function ({ commit }) {
			commit('resetSearchFilter')
			return commit('resetFilter')
		},
		reset: function ({ commit, dispatch }) {
			Object.keys(modules)
				.filter(moduleName => typeof modules[moduleName]?.actions?.reset === 'function')
				.forEach(moduleName => {
					dispatch(`${moduleName}/reset`)
				})

			return commit('reset')
		}
	},
	getters: {
		structure: function (state) {
			return state.treeStructure
		},
		findParentNodeInStructure: function (state) {
			return function (nodeIdentifier) {
				return state.treeStructure?.findParentNode(nodeIdentifier)
			}
		},
		findNodeInStructure: function (state) {
			return function (nodeIdentifier, isFiltering) {
				if (isFiltering) {
					return state.filter.search.results?.findNode(nodeIdentifier)
				}

				return state.treeStructure?.findNode(nodeIdentifier)
			}
		},
		getDocumentVersion: function (state) {
			return function (version) {
				return state.treeStructure?.findNode(version?.document_id)?.getVersion(version)
			}
		},
		trashed: function (state) {
			const structure = state.isFiltering ? state.filter.search.results : state.treeStructure
			const elements = structure?.findNode('trash')
			return elements ? [elements] : []
		},
	}
}
